import React from 'react'
import * as widont from 'widont'
import cn from 'classnames'
import { Section } from '@soundtrackyourbrand/ui/src/Section'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import * as styles from './TrustedBy.module.scss'
import { graphql } from 'gatsby'

const TrustedBy = ({ block }) => {
  const title = widont(block.title)
  const images = block.images

  return (
    <Section className={cn(styles.container)}>
      <div className={cn(styles.trustedByContainer)}>
        <div className={styles.headlineContainer}>
          <p className={cn('c-12 m-6 text5', styles.headliner)}>{title}</p>
        </div>
        {images.map((image) => {
          return (
            <div
              key={image.originalId}
              className={cn(styles.trustedByIconContainer)}
            >
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt}
                className={styles.trustedByIcon}
                objectFit={'contain'}
              />
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default TrustedBy

TrustedBy.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        originalId: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        gatsbyImageData: PropTypes.object.isRequired,
      })
    ).isRequired,
  }),
};

export const query = graphql`
  fragment TrustedBy on DatoCmsTrustedby {
    model {
      apiKey
    }
    id: originalId
    title
    images {
      gatsbyImageData(height: 72)
      alt
      originalId
    }
  }
`
